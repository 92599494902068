import React, { useState, useEffect } from 'react';
import whitelist from './whitelist.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Tracker.css';

function Tracker() {
const [Whitelisted, setWhitelisted] = useState(false);
const [NotWhitelisted, setNotWhitelisted] = useState(false);
const [emptyInput, setEmptyInput] = useState(false);

const handleSubmit = event => {
  event.preventDefault();
  // check if the input is empty
  if (!event.target.address.value) {
    setNotWhitelisted(false);
    setWhitelisted(false);
    setEmptyInput(true);
    toast("Please entre your wallet address !!", {type: 'warn', position: toast.POSITION.TOP_CENTER});
    return;
  }
  // Check if the input address is in the whitelist
  if (whitelist.includes(event.target.address.value.toLowerCase())) {
    setWhitelisted(true);
    toast("Congratulations! Your wallet address is whitelisted", {type: 'success', position: toast.POSITION.TOP_CENTER});
    setNotWhitelisted(false);
    setEmptyInput(false);
  } else {
    setNotWhitelisted(true);
    toast("Your wallet address is not whitelisted", {type: 'error', position: toast.POSITION.TOP_CENTER});
    setWhitelisted(false);
    setEmptyInput(false);
  }
};

useEffect(() => {
  setTimeout(() => {
    setNotWhitelisted(false);
  }, 30000);
}, []);

  return (
    <div className='main'>
      <div className='Check'>
      <form onSubmit={handleSubmit} >
          <input type="text" name="address" placeholder="Wallet Address "/>
        <button className='check-button' type="submit">CHECK WALLET 4 WL</button>
        
        {/* {Whitelisted ? <div className='Whitelisted'>Congratulations! Your wallet address is whitelisted</div> : null} */}
      </form>
      {/* {NotWhitelisted ?  <div className='NotWhitelisted'>Don't worry, you can <a href='https://www.premint.xyz/the-zabas/'  target="_blank" style={{ color: "#FFFF00",}}>apply to be whitelisted</a> by visiting our form.</div> : null} */}
     <ToastContainer />
     </div>
  </div>
  );
}

export default Tracker;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import whitelistAddresses from "./whitelist/whitelist";
import TheZabas from "./contracts/TheZabas.json";
import ModalVideo from 'react-modal-video';
import { toast, ToastContainer } from 'react-toastify';
import Tracker from "./whitelist/Tracker";
import 'react-toastify/dist/ReactToastify.css';
import "./app.css";
import "./modal-video.scss";
import Lottie from 'react-lottie';
import animationData from './logo/AssnLogo.json';

// Add this import line at the top
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3("https://eth-mainnet.g.alchemy.com/v2/vKxcl-h1IxGl9B-BoLTTtTaQE2vdwvdf");
// const web3 = createAlchemyWeb3("https://eth-goerli.g.alchemy.com/v2/xr7BwhoTcHOid8kbXjAvgj5oZH0qIOzk");
var Web3 = require('web3');
var Contract = require('web3-eth-contract');
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// Whitelist MerkleTree
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const rootHash = merkleTree.getRoot();
// console.log('Whitelist Merkle Tree\n', merkleTree.toString());


function App() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintDone, setMintDone] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showcountdown, setShowcountdown] = useState(false);
  const [showpublicMint, setShowpublicMint] = useState(false);
  const [showwhiteList, setShowwhiteList] = useState(false);
  const [supply, setTotalSupply] = useState(0);
  const [acountMinted, setMinted] = useState(0);
  const [mintLimit, setMintLimit] = useState(0);
  const [mintsLeft, setMintsLeft] = useState(0);
  const [NftCost, setNftCost] = useState(0);
  const [mintFee, setMintFee] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [statusAlert, setStatusAlert] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [IsVisible, SetIsVisible] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [displayCost, setDisplayCost] = useState(0);
  const [state, setState] = useState(-1);
  const [canMintWL, setCanMintWL] = useState(false);
  const [disable, setDisable] = useState(false);
  const [proof, setProof] = useState([]);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "0xE96d3a6B52993377C476dE24D86871023046787a",
    // CONTRACT_ADDRESS: "0xd5510BaA72a65A2b7d8fC6263802AD26f3514ff9",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    TWITTER_LINK: "",
    DISCORD_LINK: "",
    TELEGRAM_LINK: "",
    MAIN_WEBSITE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = async () => {
    let cost = NftCost;//in wei
    cost = Web3.utils.toWei(String(cost), "ether");

    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    setClaimingNft(true);
    // setOpen(true);

    const mintMethod = (state == 1)
      ? blockchain.smartContract.methods.wlMint(mintAmount, proof)
      : blockchain.smartContract.methods.mint(mintAmount)

    mintMethod
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        toast("Sorry, something went wrong please try again.", {type: 'error', position: toast.POSITION.TOP_CENTER});
        setClaimingNft(false);
        // setOpen(false);
      })
      .then((receipt) => {
        setMintDone(true);
        toast("Congrats! Your mint was successful!", {type: 'success', position: toast.POSITION.TOP_CENTER});
        SetIsVisible(true);
        setClaimingNft(false);
        // setOpen(false);
        blockchain.smartContract.methods
          .totalSupply()
          .call()
          .then((res) => {
            setTotalSupply(res);
          });
        dispatch(fetchData(blockchain.account));
        getData();
      });
  };


  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
    setDisplayCost(
      parseFloat(NftCost * newMintAmount).toFixed(4)
    );
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    newMintAmount > mintsLeft
      ? (newMintAmount = mintsLeft)
      : newMintAmount;
    setDisplayCost(
      parseFloat(NftCost * newMintAmount).toFixed(4)
    );
    setMintAmount(newMintAmount);
  };

  const maxNfts = () => {
    setMintAmount(mintsLeft);

    setDisplayCost(
      parseFloat(NftCost * mintsLeft).toFixed(4)
    );

  };

  const getData = async () => {
    if (blockchain.account) {
      dispatch(fetchData(blockchain.account));
      let contract = new Contract(TheZabas, CONFIG.CONTRACT_ADDRESS);
      contract.setProvider(web3.currentProvider);
      const totalSupply = await contract.methods
        .totalSupply()
        .call();
      setTotalSupply(totalSupply);

      //0, 1, 2 = paused, wl, open
      let currentState = await contract.methods
        .mintStage()
        .call();
      setState(currentState);

      //number of mints per account
      let acountMinted = await contract.methods
        .limitIndex(blockchain.account.toLowerCase())
        .call();
      setMinted(acountMinted);

      //max number of mints (3 in WL mintStage, 1 in open mintStage, may change)
      let mintLimit = await contract.methods
        .limit()
        .call();
      setMintLimit(mintLimit);


      let mintsLeft = mintLimit > acountMinted ? mintLimit - acountMinted : 0;
      setMintsLeft(mintsLeft);

      //mint fee in wei, may change
      let mintFee = await contract.methods
        .fee()
        .call();
      setMintFee(mintFee);

      let WLmint = mintsLeft - 1;

      //mint states
      if (currentState == 1) {
        let totalWLNfts = 4444;
        supply < totalWLNfts && mintsLeft != 0 ? setDisable(false) : setDisable(true);
        const claimingAddress = keccak256(blockchain.account.toLowerCase());
        // `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
        // be required to derive the Merkle Trees root hash.
        const hexProof = merkleTree.getHexProof(claimingAddress);
        setProof(hexProof);
        let mintWL = merkleTree.verify(hexProof, claimingAddress, rootHash);
        let mintWLContractMethod = await contract.methods
          .isWhitelisted(blockchain.account.toLowerCase(), hexProof)
          .call();
        if (mintWLContractMethod && mintWL) {
          setCanMintWL(mintWL);
          toast(`Welcome Whitelist Member, you can mint up to ${mintsLeft} NFTs`, {type: 'success', position: toast.POSITION.TOP_CENTER});
          setFeedback(`Welcome Whitelist Member, you can mint up to ${mintsLeft} NFTs`)
        } else {
          toast("Sorry, your wallet is not on the whitelist.", {type: 'error', position: toast.POSITION.TOP_CENTER});
          setDisable(true);
        }
        if (mintsLeft == 0) {
          setFeedback("Max mints exceeded.");
        } 
      } else if (currentState == 2) {
        let totalPublic = 4444;
        supply < totalPublic && mintsLeft != 0 ? setDisable(false) : setDisable(true);
        if (mintsLeft == 0) {
          setFeedback("Max mints exceeded.");
        } else {
          setFeedback(`Welcome, you can mint up to ${mintsLeft} NFTs`);
        }
      }
    }
  };

  const getDataWithAlchemy = async () => {
/*
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
*/
    let contract = new Contract(TheZabas, CONFIG.CONTRACT_ADDRESS);
    contract.setProvider(web3.currentProvider);
    // Get Total Supply
    const totalSupply = await contract.methods
      .totalSupply()
      .call();
    setTotalSupply(totalSupply);

    //0, 1, 2 = paused, wl, open
    let currentState = await contract.methods
      .mintStage()
      .call();
    setState(currentState);
/*
    //number of mints per account
    let acountMinted = await contract.methods
      .limitIndex(blockchain.account)
      .call();
    setMinted(acountMinted);
*/
    //max number of mints (3 in WL mintStage, 1 in open mintStage, may change)
    let mintLimit = await contract.methods
      .limit()
      .call();
    setMintLimit(mintLimit);

    let mintsLeft = mintLimit;//init
    setMintsLeft(mintsLeft);

    //mint fee in wei, may change
    let mintFee = await contract.methods
      .fee()
      .call();
    setMintFee(mintFee);

    // Set Price and Max According to State
    if (currentState == 0) {
      setStatusAlert("MINT NOT LIVE YET!");
      // setDisable(true);
      setDisplayCost(0);
      setNftCost(0);
      setMintsLeft(0);
      setShowpublicMint(true);
      setShowwhiteList(true);
    }
    else if (currentState == 1) {
      setDisplayCost(web3.utils.fromWei(mintFee));
      setNftCost(web3.utils.fromWei(mintFee));
      setStatusAlert("WHITELIST IS NOW LIVE!");
      setShowcountdown(true);
      setShowpublicMint(true);
    }
    else {//currentState == 2
      setDisplayCost(web3.utils.fromWei(mintFee));
      setNftCost(web3.utils.fromWei(mintFee));
      setStatusAlert("PUBLIC MINT IS LIVE");
      setShowcountdown(true);
      setShowwhiteList(true);
    }

  }

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    getDataWithAlchemy();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mintDate = new Date('May 31, 2023 13:00:00 UTC').getTime();

  useEffect(() => {
    addScript("all.js", () => {
      document.addEventListener('readystatechange', event => {
        var clockdiv = document.getElementsByClassName("countdown");
        var countDownDate = new Array([]);
  
        for (var i = 0; i < clockdiv.length; i++) {
          countDownDate[i] = [];
          countDownDate[i]['el'] = clockdiv[i];
          countDownDate[i]['time'] = mintDate;
          countDownDate[i]['days'] = 0;
          countDownDate[i]['hours'] = 0;
          countDownDate[i]['seconds'] = 0;
          countDownDate[i]['minutes'] = 0;
        }
  
        var countdownfunction = setInterval(function () {
          for (var i = 0; i < countDownDate.length; i++) {
            var now = Date.now(); // Get current time in UTC
            var distance = countDownDate[i]['time'] - now;
            countDownDate[i]['days'] = Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
            countDownDate[i]['hours'] = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
            countDownDate[i]['minutes'] = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
            countDownDate[i]['seconds'] = Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0');
  
            if (distance < 0) {
              countDownDate[i]['el'].querySelector('.countdown__number-days').innerHTML = "00";
              countDownDate[i]['el'].querySelector('.countdown__number-hours').innerHTML = "00";
              countDownDate[i]['el'].querySelector('.countdown__number-minutes').innerHTML = "00";
              countDownDate[i]['el'].querySelector('.countdown__number-seconds').innerHTML = "00";
              clearInterval(countdownfunction);
              const DateMint = document.getElementById('countdown-container');
              DateMint.style.display = 'none';
            } else {
              countDownDate[i]['el'].querySelector('.countdown__number-days').innerHTML = countDownDate[i]['days'];
              countDownDate[i]['el'].querySelector('.countdown__number-hours').innerHTML = countDownDate[i]['hours'];
              countDownDate[i]['el'].querySelector('.countdown__number-minutes').innerHTML = countDownDate[i]['minutes'];
              countDownDate[i]['el'].querySelector('.countdown__number-seconds').innerHTML = countDownDate[i]['seconds'];
            }
          }
        }, 1000);
      });
    });
  }, [mintDate]);
  

  const addScript = (src, onload) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = onload;
    document.body.appendChild(script);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
    <div className="section-mint-main">
      {blockchain.errorMsg !== "" ? (
      toast.error(blockchain.errorMsg)
       ) : null}
      <div className="page-padding">
      <div className="cont-logo">
      <a href="https://www.thezabas.com/" target="_blank" rel="noopener noreferrer">
      {/* <img alt="logo" className="logo-zabas" src="/config/images/logo.png"></img> */}
      <Lottie 
	    options={defaultOptions}
      />
      </a>
      </div>


        <div className="container-large"> 



         {isMobile ? (
              <>
          <div className="padding-vertical padding-huge">
            <div className="mint-heading">
            <img alt="market" className="logo" src="/config/images/market.png"></img>
            </div>
            <ModalVideo channel='youtube' autoplay controls="0"  allowFullScreen isOpen={isOpen} videoId="gfGuPd1CELo" onClose={() => setOpen(false)} />
            <div className="w-layout-grid mint-component">
            <div className="mint-box padding-large">
             <div id="countdown-container" className='countdown-container' data-aos="zoom-in" data-aos-duration="2000"  style={{display: showcountdown ? 'none' : 'block'}}>
              <ul id="countdown" className="countdown justify-content-center text--3d2">
              <h2 className="heading-medium text-color-blue">MINT:</h2>   
              <li className="countdown__item">
              <h3 className="countdown__number color--theme-color countdown__number-days">99</h3>
              <p className="countdown__text">Days</p>
              </li>
              <span className="countdown__between">:</span>
                 <li className="countdown__item">
                   <h3 className="countdown__number color--theme-color countdown__number-hours">18</h3>
                      <p className="countdown__text">Hours</p>
                        </li>
                        <span className="countdown__between">:</span>
                       <li className="countdown__item">
                      <h3 className="countdown__number color--theme-color countdown__number-minutes">44</h3>
                    <p className="countdown__text">Minutes</p>
                   </li>
                  <span className="countdown__between">:</span>
                  <li className="countdown__item">
                  <h3 className="countdown__number color--theme-color countdown__number-seconds">36</h3>
                 <p className="countdown__text">Seconds</p>
             </li>
           </ul>
         </div>

              {/* <div>
              <h2 className="heading-medium text-color-blue" style={{display: showpublicMint ? 'none' : 'block'}}>PUBLIC MINT</h2>   
              <h2 className="heading-medium text-color-blue" style={{display: showwhiteList ? 'none' : 'block'}}>WHITELIST MINT</h2>   
              </div> */}

         <div className=".margin-top margin-small">
             {blockchain.account === "" ||
              blockchain.smartContract === null ? (
              <div>
                </div>
                ) : (
                <>
              <div className=".margin-top margin-small">
             <div className="text-weight-semibold">
              <span className="number-minted" style={{ marginLeft: "5px" }}>
              {supply} &nbsp;
              </span> 
               NFT's &nbsp; Minted
              </div>
              </div>
              </>
                  )}
               </div>
               <div className="mint-item">
                <div className="mint-info-text">Cost</div>
                <div className="mint-info-text text-weight-bold">
                  {/* <span id="balance-number" className="balance-number"> TBA </span></div> */}
                  <span id="balance-number" className="balance-number"> {NftCost} </span> ETH</div>
                  </div>
                  <div className="mint-item">
                  <div className="mint-info-text">Amount</div>
                  <div className="counter-wrapper">
                    <button className="mint-minus-button" 
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                          }}>
                       −
                      </button>
                      <div className="number-wrapper">
                        <div className="mint-amount-number">
                        {mintAmount}
                          </div>
                        </div>

                      <button  className="mint-plus-button" 
                           disabled={claimingNft ? 1 : 0}
                           onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                           }}
                         >
                          +
                      </button>

                     <button className="mint-max-button"
                       disabled={claimingNft ? 1 : 0}
                       onClick={(e) => {
                       e.preventDefault();
                       maxNfts();
                     }}
                     >
                      MAX
                    </button>

                    </div>
                    </div>

                    <div className="mint-item">
                    <div className="mint-info-text">TOTAL</div>
                    <div className="mint-info-text text-weight-bold">
                    {/* <span className="balance-number"> TBA </span> */}

                    <span className="balance-number">{displayCost}</span> ETH</div>
                     </div>
                     {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                     <>
                    <p className="TextTitle">
                     The sale has ended.
                    </p>
                    <p className="TextDescription">
                     You can still find {CONFIG.NFT_NAME} on
                     </p>
                     <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK} rel="noopener noreferrer">
                      {CONFIG.MARKETPLACE}
                     </a>
                      </>
                      ) : (
                      <>
                     {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                       <div className="mint-connect">
                       <button className="button is--mint w-button"
                        // disabled={state == 0 ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connectWallet());
                          getData();
                        }}>
                        CONNECT WALLET
                        </button>
                        </div>
                     ) : (
                    <>
                      {IsVisible &&
                      <div style={{ fontFamily: "Montserrat", textAlign: "center", color: "var(--accent-text)", fontSize: 25, }}>
                      Congrats! Your mint was successful! Please <a href={CONFIG.MARKETPLACE_LINK} target="_blank" rel="noopener noreferrer" style={{ color: "#cb1f8d",}}> click here </a> to view your NFT on OpenSea.
                      </div>
                       }
                        <div className="mint-connect">
                        <button className="button is--mint w-button"
                          disabled={disable}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "MINTING" : "MINT"}
                        </button>
                      </div>
                      {feedback}
                    </>
                  )}
                </>
              )}
            </div>

           <div id="w-node-_7091a729-31c6-d62d-5b76-ecf964dc09cd-dac2bddc" data-w-id="7091a729-31c6-d62d-5b76-ecf964dc09cd"  className="cube-animation-section wf-section">
              <div className="demo-container">
                  <div className="demo-wrapper">
                     <div className="egg"  >
                     <video className='backVideo'  width='100%' height='100%' autoPlay={true} loop muted={true} >
                     <source src="/config/video/Oblivion_by_The_Zabas.mp4" type="video/mp4" />
                     </video>
                      </div>
                  </div>
                </div>
                {/* <div className="deo-container">
                <Tracker />
                </div> */}
             </div>
           </div>
        <div className="margin-top margin-huge">
         <div className="w-layout-grid mint-info">
           <div id="w-node-_24a77efa-00b4-4e24-9d0c-fec2a98fb011-dac2bddc" className="mint-info-item">
           <div className="sub-heading-wrapper">
            <div className="sub-heading-number" >01</div>
             <div className="sub-heading-line" >
               </div>
                <div className="sub-heading" >TOTAL</div>
                </div>
                 <div className="margin-top margin-small">
                 <div className="mint-box padding-small">
                  <h3 className="text-align-center text-color-blue">4444</h3>
                  <div className="margin-top margin-small">
                  <div className="text-align-center">A 3D collection of 4444 diverse ZABA$ accompanied by 1000 Good Boys and Girls on $ETH serving The High Table <span role="img" aria-label="i">🏴‍☠️</span><br/></div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div id="w-node-_654e8183-3a0c-e4e4-b5a7-2d5ab737aa0c-dac2bddc" className="mint-info-item">
                   <div className="sub-heading-wrapper">
                   <div className="sub-heading-number" >02</div>
                   <div className="sub-heading-line" ></div>
                    <div className="sub-heading" >Amazing community</div>
                    </div>
                     <div className="margin-top margin-small">
                      <div className="mint-box padding-small">
                      <h3 className="text-align-center text-color-blue">Community</h3>
                      <div className="margin-top margin-small">
                      <div className="text-align-center">The greatness of a community is most accurately measured by the actions of its members. We love our community!</div>
                       </div>
                       </div>
                       </div>
                       </div>
                       <div id="w-node-_5fce823b-59ba-7810-822f-eac45cf15888-dac2bddc" className="mint-info-item">
                        <div className="sub-heading-wrapper">
                        <div className="sub-heading-number">03</div>
                        <div className="sub-heading-line" ></div>
                        <div className="sub-heading" >A$$N Protocol</div>
                        </div>
                        <div className="margin-top margin-small">
                        <div className="mint-box padding-small">
                        <h3 className="text-align-center text-color-blue">Protocol</h3>
                        <div className="margin-top margin-small">
                       <div className="text-align-center">The Assassin Protocol is a new generation platform combining classic DeFi with NFTs and gaming.</div>
                      </div>
                     </div>
                   </div>
                 </div>
              </div>
           </div>
         </div>
             </>
             ) : (
             <>
          <div className="padding-vertical padding-huge">
            <div className="mint-heading">
            <img alt="logo" className="logo" src="/config/images/market.png"></img>
            </div>
            <ModalVideo channel='youtube' autoplay controls="0"  allowFullScreen isOpen={isOpen} videoId="gfGuPd1CELo" onClose={() => setOpen(false)} />
            {/* <Tracker /> */}

            <div className="w-layout-grid mint-component">
            <div className="mint-box padding-large">
             <div id="countdown-container" className='countdown-container' data-aos="zoom-in" data-aos-duration="2000"  style={{display: showcountdown ? 'none' : 'block'}}>
              <ul id="countdown" className="countdown justify-content-center text--3d2">
              <h2 className="heading-medium text-color-blue">MINT:</h2>   
              <li className="countdown__item">
              <h3 className="countdown__number color--theme-color countdown__number-days">99</h3>
              <p className="countdown__text">Days</p>
              </li>
              <span className="countdown__between">:</span>
                 <li className="countdown__item">
                   <h3 className="countdown__number color--theme-color countdown__number-hours">18</h3>
                      <p className="countdown__text">Hours</p>
                        </li>
                        <span className="countdown__between">:</span>
                       <li className="countdown__item">
                      <h3 className="countdown__number color--theme-color countdown__number-minutes">44</h3>
                    <p className="countdown__text">Minutes</p>
                   </li>
                  <span className="countdown__between">:</span>
                  <li className="countdown__item">
                  <h3 className="countdown__number color--theme-color countdown__number-seconds">36</h3>
                 <p className="countdown__text">Seconds</p>
             </li>
           </ul>
         </div>

              {/* <div>
              <h2 className="heading-medium text-color-blue" style={{display: showpublicMint ? 'none' : 'block'}}>PUBLIC MINT</h2>   
              <h2 className="heading-medium text-color-blue" style={{display: showwhiteList ? 'none' : 'block'}}>WHITELIST MINT</h2>   
              </div> */}

         <div className=".margin-top margin-small">
             {blockchain.account === "" ||
              blockchain.smartContract === null ? (
              <div>
                </div>
                ) : (
                <>
              <div className=".margin-top margin-small">
             <div className="text-weight-semibold">
              <span className="number-minted" style={{ marginLeft: "5px" }}>
              {supply} &nbsp;
              </span> 
               NFT's &nbsp; Minted
              </div>
              </div>
              </>
                  )}
               </div>
               <div className="mint-item">
                <div className="mint-info-text">Cost</div>
                <div className="mint-info-text text-weight-bold">
                  <span id="balance-number" className="balance-number"> {NftCost} </span> ETH</div>
                  {/* <span id="balance-number" className="balance-number"> TBA </span></div> */}
                  </div>
                  <div className="mint-item">
                  <div className="mint-info-text">Amount</div>
                  <div className="counter-wrapper">
                    <button className="mint-minus-button" 
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                          }}>
                       −
                      </button>
                      <div className="number-wrapper">
                        <div className="mint-amount-number">
                        {mintAmount}
                          </div>
                        </div>

                      <button  className="mint-plus-button" 
                           disabled={claimingNft ? 1 : 0}
                           onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                           }}
                         >
                          +
                      </button>

                     <button className="mint-max-button"
                       disabled={claimingNft ? 1 : 0}
                       onClick={(e) => {
                       e.preventDefault();
                       maxNfts();
                     }}
                     >
                      MAX
                    </button>

                    </div>
                    </div>

                    <div className="mint-item">
                    <div className="mint-info-text">TOTAL</div>
                    <div className="mint-info-text text-weight-bold">
                    {/* <span className="balance-number">TBA</span></div> */}
                    <span className="balance-number">{displayCost}</span> ETH</div>
                     </div>
                     {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                     <>
                    <p className="TextTitle">
                     The sale has ended.
                    </p>
                    <p className="TextDescription">
                     You can still find {CONFIG.NFT_NAME} on
                     </p>
                     <a target={"_blank"} href={CONFIG.MARKETPLACE_LINK} rel="noopener noreferrer">
                      {CONFIG.MARKETPLACE}
                     </a>
                      </>
                      ) : (
                      <>
                     {blockchain.account === "" ||
                      blockchain.smartContract === null ? (
                       <div className="mint-connect">
                       <button className="button is--mint w-button"
                        // disabled={state == 0 ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connectWallet());
                          getData();
                        }}>
                        CONNECT WALLET
                        </button>
                        </div>
                     ) : (
                    <>
                      {IsVisible &&
                      <div style={{ fontFamily: "Montserrat", textAlign: "center", color: "var(--accent-text)", fontSize: 25, }}>
                      Congrats! Your mint was successful! Please <a href={CONFIG.MARKETPLACE_LINK} target="_blank" rel="noopener noreferrer" style={{ color: "#cb1f8d",}}> click here </a> to view your NFT on OpenSea.
                      </div>
                       }
                        <div className="mint-connect">
                        <button className="button is--mint w-button"
                          disabled={disable}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "MINTING" : "MINT"}
                        </button>
                      </div>
                      {feedback}
                    </>
                  )}
                </>
              )}
            </div>

           <div id="w-node-_7091a729-31c6-d62d-5b76-ecf964dc09cd-dac2bddc" data-w-id="7091a729-31c6-d62d-5b76-ecf964dc09cd"  className="cube-animation-section wf-section">
              <div className="demo-container">
                  <div className="demo-wrapper">
                     <div className="egg"  >
                     <video className='backVideo'  width='100%' height='100%' autoPlay={true} loop muted={true} >
                     <source src="/config/video/Oblivion_by_The_Zabas.mp4" type="video/mp4" />
                     </video>
                      </div>
                  </div>
                </div>
             </div>
           </div>


        <div className="margin-top margin-huge">
         <div className="w-layout-grid mint-info">
           <div id="w-node-_24a77efa-00b4-4e24-9d0c-fec2a98fb011-dac2bddc" className="mint-info-item">
           <div className="sub-heading-wrapper">
            <div className="sub-heading-number" >01</div>
             <div className="sub-heading-line" >
               </div>
                <div className="sub-heading" >TOTAL</div>
                </div>
                 <div className="margin-top margin-small">
                 <div className="mint-box padding-small">
                  <h3 className="text-align-center text-color-blue">4444</h3>
                  <div className="margin-top margin-small">
                  <div className="text-align-center">4444 Unique ZABA$ on the Ethereum Blockchain that Unite under The High Table's Banner <span role="img" aria-label="i">🏴‍☠️</span><br/></div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div id="w-node-_654e8183-3a0c-e4e4-b5a7-2d5ab737aa0c-dac2bddc" className="mint-info-item">
                   <div className="sub-heading-wrapper">
                   <div className="sub-heading-number" >02</div>
                   <div className="sub-heading-line" ></div>
                    <div className="sub-heading" >Amazing community</div>
                    </div>
                     <div className="margin-top margin-small">
                      <div className="mint-box padding-small">
                      <h3 className="text-align-center text-color-blue">Community</h3>
                      <div className="margin-top margin-small">
                      <div className="text-align-center">The greatness of a community is most accurately measured by the actions of its members. We love our community!</div>
                       </div>
                       </div>
                       </div>
                       </div>
                       <div id="w-node-_5fce823b-59ba-7810-822f-eac45cf15888-dac2bddc" className="mint-info-item">
                        <div className="sub-heading-wrapper">
                        <div className="sub-heading-number">03</div>
                        <div className="sub-heading-line" ></div>
                        <div className="sub-heading" >A$$N Protocol</div>
                        </div>
                        <div className="margin-top margin-small">
                        <div className="mint-box padding-small">
                        <h3 className="text-align-center text-color-blue">Protocol</h3>
                        <div className="margin-top margin-small">
                       <div className="text-align-center">The Assassin Protocol is a new generation platform combining classic DeFi with NFTs and gaming.</div>
                      </div>
                     </div>
                   </div>
                 </div>
              </div>
           </div>
         </div>
             </>
             )}
        </div> 
      </div>

    <div className="footer">
      <div className="ContainerFooter">
      <a href={CONFIG.DISCORD_LINK} target="_blank" rel="noopener noreferrer">
        <img alt="logo" width="28" heigth="28" src="/config/images/discord.png"></img>
      </a>
      <a href={CONFIG.TWITTER_LINK} target="_blank" rel="noopener noreferrer">
        <img alt="logo" width="28" heigth="28" src="/config/images/twitter.png"></img>
      </a>
      <a href={CONFIG.TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
        <img alt="logo" width="28" heigth="28" src="/config/images/telegram.svg"></img>
      </a>
      <a href={CONFIG.MARKETPLACE_LINK} target="_blank" rel="noopener noreferrer">
        <img alt="logo" width="28" heigth="28" src="/config/images/opensea.png"></img>
      </a>
      </div>
        <p className="TextFooter" >
        The High Table LLC - Copyright © 2024 all Rights Reserved
        </p>
    </div>
    <ToastContainer />
  </div>
    </>
  );
}

export default App;